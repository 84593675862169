<template>
  <div class="utilityBillView">
    <CSTabBar
        :tabbar="tabBar"
        :checked-tab="checkedTaBar"
        @changeTabBar="changeTabBar"
    />

    <template v-if="checkedTaBar == 1">
      <div class="filter-panel">
        <CSSelect style="margin-right: 0; width: 185px">
          <el-date-picker
              v-model="conditions.startTime"
              style="width: 157px"
              format="yyyy-MM-dd"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择开始时间"
              prefix-icon="el-icon-time"
              :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"></span>
        <CSSelect style="width: 185px">
          <el-date-picker
              v-model="conditions.endTime"
              style="width: 157px"
              format="yyyy-MM-dd"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择结束时间"
              prefix-icon="el-icon-time"
              :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <CSSelect>
          <select v-model="conditions.contractState">
            <option
                v-for="name in contractStates"
                :key="name.id"
                :value="name.id"
            >
              {{ name.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect>
          <select v-model="conditions.billState">
            <option value="">账单周期不限</option>
            <option
                v-for="(item, index) in billStates"
                :key="index"
                :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </CSSelect>
        <div
            style="
          vertical-align: middle;
          margin-bottom: 15px;
          display: inline-block;
        "
        >
          <input
              type="text"
              placeholder="搜索账单号/业主/租客/房间"
              v-model="conditions.queryInput"
              style="
            color: #000;
            width: 320px;
            height: 30px;
            padding-left: 10px;
            border-radius: 4px;
            border: 1px solid #979797;
            margin-right: 20px;
          "
          />
        </div>
        <button type="button" class="btn btn-primary btn-sm" @click="query()">
          查询
        </button>
      </div>

      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel">
              <span>账单数: {{ total }}</span>
              <span>应缴费用总计：{{ feePayable }}元</span>
              <span>减免金额总计：{{ creditAmount }}元</span>
              <span>实缴费用总计：{{ actualPayable }}元</span>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>账单生成时间</th>
              <th>账单号</th>
              <th>账单状态</th>
              <th>业主/租客</th>
              <th>房间</th>
              <th>总面积</th>
              <th>计费标准</th>
              <th>账单周期</th>
              <th>物业计费期限</th>
              <th>应缴费用（元）</th>
              <th>附加费用（元）</th>
              <th>减免金额（元）</th>
              <th>实缴费用（元）</th>
              <th>修改人</th>
              <th>备注</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="item in utilityList" :key="item.id">
              <td>{{ item.createTime }}</td>
              <td>{{ item.billNumber }}</td>
              <td>
                <template v-if="item.state != 1">{{
                    contractStates[item.state + 1].name
                  }}
                </template>
                <template v-else>
                  <span class="btn-active" @click="seeState(item)">已缴费</span>
                </template>
              </td>
              <td>{{ item.lesseeName }}</td>
              <td>
                <span class="btn-active" @click="seeRoomBtn(item)">查看</span>
              </td>
              <td>{{ item.totalArea }}m²</td>
              <td>{{ item.ruleCharging }}元/m²/月</td>
              <td>
                {{
                  billArr[item.billType - 1] ? billArr[item.billType - 1] : "-"
                }}-{{
                  billArrs[item.billPeriod - 1]
                      ? billArrs[item.billPeriod - 1]
                      : "-"
                }}
              </td>
              <td>{{ item.startDate }}<br/>~<br/>{{ item.endDate }}</td>
              <td>{{ item.feePayable }}</td>
              <td>{{ item.additionalPayable }}</td>
              <td>{{ item.creditAmount }}</td>
              <td>{{ item.actualPayable }}</td>
              <td>{{ item.updateUser ? item.updateUser : "-" }}</td>
              <td>
                <span v-if="!item.commit">-</span>
                <span
                    v-else
                    class="btn-active"
                    @click="seeRemarksBtn(item.commit)"
                >查看</span
                >
              </td>
              <td>
                <div class="btn-group">
                  <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      :disabled="item.state == 1 || item.state == 2"
                      :class="[item.state == 1 || item.state == 2? 'disClss':'']"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="width: 76px">
                    <li @click="cancelBill(item)">
                      <a>取消账单</a>
                    </li>
                    <li @click="modifyBill(item)">
                      <a>修改账单</a>
                    </li>
                    <li @click="offlinePaymentBtn(item)">
                      <a>线下缴费</a>
                    </li>
                    <li @click="codeScanningPayment(item)">
                      <a>扫码支付</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <!-- //分页 -->
        <Pagination name="pagination" componentName="Pagination"></Pagination>
      </div>
      <!-- //查看房间显示框 -->
      <GetHydropower
          name="getHydropower"
          componentName="GetHydropower"
      ></GetHydropower>
      <!-- //取消提示 -->
      <DeleteOwnerOver></DeleteOwnerOver>
      <!-- 修改账单 -->
      <ModifyBillS/>
      <!-- //线下付款 -->
      <OfflinePaymentBill></OfflinePaymentBill>
      <!-- 微信二维码 -->
      <WechatPayment></WechatPayment>
    </template>
    <template v-if="checkedTaBar === 2">
      <PropertyFeeRules></PropertyFeeRules>
    </template>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import OfflinePaymentBill from "@/components/OfflinePaymentBill";
import dayjs from "dayjs";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination.vue";
import CSTable from "@/components/common/CSTable";
import GetHydropower from "@/components/GetHydropower";
import DeleteOwnerOver from "@/components/DeleteOwnerOver.vue";
import ModifyBillS from "@/components/ModifyBillS";
import WechatPayment from "@/components/WechatPayment";
import PropertyFeeRules from "@/views/UtilityBill/PropertyFeeRules";
import {
  queryBillListsUrl,
  queryRoomByPCIdUrl,
  cancelBillUtilltyUrl,
  queryPropPaymentMethodUrl,
} from "@/requestUrl";

export default {
  data() {
    return {
      pag: 1,
      total: "-",
      feePayable: "-", //应缴费
      creditAmount: "-", //减免
      actualPayable: "-", //实际缴费
      //账单列表
      utilityList: [],
      filterHeight: "",
      checkedTaBar: 1,
      tabBar: {
        1: "物业账单",
        2: "缴费面积统计",
      },
      conditions: {
        startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
        endTime: dayjs().format("YYYY-MM-DD"),
        contractState: "", //账单状态
        billState: "", //账单周期
        queryInput: "", //房间查询信息
      },
      //账单状态
      contractStates: [
        {id: "", name: "账单状态不限"},
        {id: 0, name: "未缴费"},
        {id: 1, name: "已缴费"},
        {id: 2, name: "已取消"},
      ],
      //账单周期状态
      billStates: [
        {id: [1, 1], name: "固定-年"},
        {id: [1, 2], name: "固定-半年"},
        {id: [1, 3], name: "固定-季度"},
        {id: [1, 4], name: "固定-月"},
        {id: [2, 1], name: "顺延-年"},
        {id: [2, 2], name: "顺延-半年"},
        {id: [2, 3], name: "顺延-季度"},
        {id: [2, 4], name: "顺延-月"},
      ],
      billArrs: ["年", "半年", "季度", "月"],
      billArr: ["固定", "顺延"],
      //支付渠道
      payChannel: ["现金支付", "银行转账", "支付宝转账", "微信转账"],
    };
  },
  created() {
    window.addEventListener("keydown", this.queryDown);
    this.query();
  },
  components: {
    CSTabBar,
    CSSelect,
    Pagination,
    CSTable,
    GetHydropower,
    DeleteOwnerOver,
    ModifyBillS,
    OfflinePaymentBill,
    WechatPayment,
    PropertyFeeRules
  },
  methods: {
    //查看凭证
    seeVoucher(item) {
      this.$CSDialog.alert({
        width: "500px",
        title: "付款凭证",
        messageHtml: `
                    <div style="width: 100%;height:400px;background: #DCDCDC;border-radius: 5px;">
                      <img  style="width:100%;height:400px" src =${item}>
                    </div>
                `,
      });
    },
    queryDown(e) {
      if (e.keyCode == 13) {
        this.query();
      }
    },
    //查询按钮
    query(pageN = 1, pageS = 10) {
      this.$fly
          .post(queryBillListsUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            startDate: this.conditions.startTime, //开始时间
            endDate: this.conditions.endTime, //结束时间
            state: this.conditions.contractState, //账单状态    0未缴费 1已缴费 2已取消
            billType: this.conditions.billState[0]
                ? this.conditions.billState[0]
                : "", //账单类型 1固定 2顺延
            billPeriod: this.conditions.billState[1]
                ? this.conditions.billState[1]
                : "", //周期类型  1年 2半年 3季度 4 月
            search: this.conditions.queryInput, //搜索框内容
            pageSize: pageS, //页面大小
            pageNo: pageN, //页码
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (pageN == 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageN,
                pageSize: pageS,
              });
            }
            this.total = res.data.total;
            this.feePayable = res.data.feePayable;
            this.creditAmount = res.data.creditAmount;
            this.actualPayable = res.data.actualPayable;
            this.utilityList = res.data.dates;
          });
    },
    //查看缴费状态
    async seeState(item) {
      let arr = await this.$fly.get(queryPropPaymentMethodUrl, {
        billId: item.id, //账单id
      });
      var messageStr = "";
      let state = this.payChannel[arr.data.payChannel];
      if (arr.data.paymentMethod == 2) {
        var tit = "";
        if (JSON.parse(arr.data.payOrder).length >= 2) {
          tit = `<div style="width: 100px;height:100px;background: #DCDCDC;border-radius: 5px; margin-right:20px" class = "enlarge__imga" >
                      <img src=${
              JSON.parse(arr.data.payOrder)[1]
          } style="width:100%;height:100%">
                    </div>`;
        }
        messageStr = `<div class="comment-panel" style="--item-label: 160px;">
            <div class="item right">
                <div class="item-label">缴费方式</div>
                <div>线下缴费</div>
            </div>
            <div class="item right">
                <div class="item-label">支付渠道</div>
                <div>${state ? state : "-"}</div>
            </div>
            <div class="item right" style = "display: flex;">
                <div class="item-label">付款凭证</div>
                <div style = "display: flex; padding-top:7px" v-if="scrList.length > 0">
                    <div style="width: 100px;height:100px;background: #DCDCDC;border-radius: 5px; margin-right:20px" class = "enlarge__imga" >
                      <img src=${
            JSON.parse(arr.data.payOrder)[0]
        } style="width:100%;height:100%">
                    </div>
                    ${tit}
                </div>
            </div>
            <div class="item right">
                <div class="item-label">备注</div>
                <div>${arr.data.payCommit ? arr.data.payCommit : "-"}</div>
            </div>
        </div>`;
      } else {
        messageStr = `<div class="comment-panel" style="--item-label: 160px;">
            <div class="item right">
                <div class="item-label">缴费方式</div>
                <div>${
            arr.data.paymentMethod == 1 ? "线上缴费" : "扫码支付"
        }</div>
            </div>
            <div class="item right">
                <div class="item-label">支付渠道</div>
                <div>${state ? state : "-"}</div>
            </div>
            <div class="item right">
                <div class="item-label">支付单号</div>
                <div>${arr.data.payOrderNo ? arr.data.payOrderNo : "-"}</div>
            </div>
        </div>`;
      }
      this.$CSDialog.alert({
        width: "710px",
        title: "已缴费",
        messageHtml: messageStr,
      });
      var that = this;
      let enlargeArr = document.getElementsByClassName("enlarge__imga");
      enlargeArr[0]?.addEventListener("click", function () {
        that.seeVoucher(JSON.parse(arr.data.payOrder)[0]);
      });
      enlargeArr[1]?.addEventListener("click", function () {
        that.seeVoucher(JSON.parse(arr.data.payOrder)[1]);
      });
    },
    //查看房间
    async seeRoomBtn(item) {
      let arr = await this.$fly.get(queryRoomByPCIdUrl, {
        pcId: item.pcId, //合同id
      });
      console.log(arr);
      this.$vc.emit("getHydropower", "number", {
        title: "查看房间",
        tiem: arr.data.map((item, index) => {
          return {
            no: item,
            id: index,
          };
        }),
      });
    },
    //查看备注
    seeRemarksBtn(item) {
      console.log(item);
      this.$CSDialog.alert({
        width: "710px",
        title: "备注",
        messageHtml: `
            <div class="comment-panel" style="--item-label: 160px;">
            <div class="item" style="text-align: center;">
                ${item}
            </div>
        </div>
        `,
      });
    },
    //取消账单
    cancelBill(item) {
      this.$vc.emit(this.$route.path, "deleteTenant", "openOwnerModelOver", {
        title: "确认取消账单吗？",
        id: item,
      });
    },
    //修改账单
    modifyBill(item) {
      this.$vc.emit(this.$route.path, "ModifyBillS", "showOn", {id: item});
    },
    //线下缴费
    offlinePaymentBtn(item) {
      this.$vc.emit(this.$route.path, "OfflinePaymentBill", "showOm", {
        id: item.id,
        actualPayable: item.actualPayable,
      });
    },
    //扫码支付
    codeScanningPayment(item) {
      this.$vc.emit(this.$route.path, "WechatPayment", "show", {value: item});
    },
    changeTabBar(index) {
      this.checkedTaBar = +index;
      if (this.checkedTaBar === 2) {

      }
    }
  },
  mounted() {
    this.$vc.on(this.$route.path, "successOff", (item) => {
      this.query(this.pag);
    });

    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });

    this.$vc.on(
        this.$route.path,
        "deleteTenantOn",
        "openOwnerModelOverOn",
        (item) => {
          console.log(item);
          this.$fly
              .get(cancelBillUtilltyUrl, {
                id: item.id.id,
              })
              .then((res) => {
                if (res.code != 0) {
                  return;
                }
                this.$vc.toast("账单取消成功");
                this.query(this.pag);
              });
        }
    );

    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pag = pageNo;
      this.query(pageNo);
    });

    this.$vc.on(this.$router.path, "modifyUpdate", (res) => {
      this.query(this.pag);
    });
  },
  beforeDestroy() {
    //取消回车查询事件
    window.removeEventListener("keydown", this.queryDown);
  },
  deactivated(){
    window.removeEventListener("keydown", this.queryDown);
  }
};
</script>

<style scoped>
.btn-active {
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
}

.table-header-panel span {
  margin-right: 30px;
}

.img-w {
  width: 20px;
  height: 20px;
}

.img-w img {
  width: 100%;
  height: 100%;
}

.disClss {
  background: #CCCCCC;
  border: none;
}
</style>
